import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { mqtt5 } from "aws-iot-device-sdk-v2";
import { fetchAuthSession } from "aws-amplify/auth";
import { AWSCognitoCredentialsProvider } from "../websockets/CredentialsProvider";
import { connect, createClient } from "../websockets/Websockets";

interface IDataContext {
  client: mqtt5.Mqtt5Client | null;
  loadingWeb: boolean;
}

export const WebsocketContext = createContext<IDataContext | undefined>(
  undefined
);

export const useWebsocketContext = () => {
  const context = useContext(WebsocketContext);
  if (!context) {
    throw new Error("useWebsocketContext must be used within a DataProvider");
  }
  return context;
};

async function refreshToken() {
  try {
    await fetchAuthSession({ forceRefresh: true });
  } catch (error) {
    console.error("Error refreshing token:", error);
  }
}

export function WebsocketProvider(props: { children: ReactNode }) {
  const [client, setClient] = useState<mqtt5.Mqtt5Client | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const connectToWebsocket = async () => {
    setLoading(true);
    const session = await fetchAuthSession();

    const provider = new AWSCognitoCredentialsProvider({
      IdentityPoolId: "us-west-2:0ab8ff49-bfb4-400d-82e8-da2ae64a50b8",
      Region: "us-west-2",
      Session: session,
    });

    await provider.refreshCredentials();

    const c = createClient(provider);
    connect(c);
    setClient(c);
    setLoading(false);
  };

  useEffect(() => {
    connectToWebsocket();
  }, []);

  setInterval(refreshToken, 20 * 60 * 1000);

  return (
    <WebsocketContext.Provider value={{ client, loadingWeb: loading }}>
      {props.children}
    </WebsocketContext.Provider>
  );
}
